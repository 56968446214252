@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

html {
    scroll-behavior: smooth;
}

html,body {
    overflow-x: hidden;
    background-color: #eaf1f9;
}

.MuiAccordion-root:before {
    top: unset !important;
    left: unset !important;
    right: unset !important;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}